<template>
  <b-table
    :data="operaciones.registro_operaciones_diarias"
    paginated
    backend-pagination
    :total="operaciones.total"
    :per-page="perPage"
    @page-change="onPageChange"
    aria-next-label="Siguiente Pagina"
    aria-previous-label="Pagina anterior"
    aria-page-label="Pagina"
    aria-current-label="Pagina actual"
    class="text-sm cursor-pointer"
    :selected.sync="selected"
    focusable
  >
    <b-table-column field="fecha" label="Fecha" v-slot="props">{{
      props.row.fecha_operacion | moment("DD-MM-YYYY")
    }}</b-table-column>
    <b-table-column
      field="valor"
      label="Valor"
      header-class="text-right"
      cell-class="text-right"
      v-slot="props"
      >{{ props.row.valor_crudo | currency | hp }}</b-table-column
    >
    <b-table-column field="origen" label="Origen" v-slot="props">
      <preview
        :id="props.row[props.row.origen][0].id"
        :tipo="getTipo(props.row.origen)"
      >
        #{{
          getTipo(props.row.origen) == "credito"
            ? props.row[props.row.origen][0].creditos_autorizados_id
            : props.row[props.row.origen][0].id
        }}
      </preview>
    </b-table-column>
    <b-table-column
      field="tipo_operacion"
      label="Tipo de operación"
      cell-class="text-left"
      v-slot="props"
      >{{ props.row.tipo_de_operacion.nombre }}</b-table-column
    >
    <b-table-column
      field="valor_moneda"
      label="Valor moneda"
      cell-class="text-right"
      header-class="text-right"
      v-slot="props"
    >
      {{ props.row.valor_moneda | currency | hp }}
    </b-table-column>
    <b-table-column
      field="moneda"
      cell-class="text-left"
      label="Moneda"
      v-slot="props"
      >{{ props.row.moneda.nombre }}</b-table-column
    >
    <b-table-column
      field="tasa_base_calculo"
      label="Tasa Base Calculo"
      v-slot="props"
      cell-class="text-right"
      header-class="text-right"
    >
      <span v-if="props.row.origen == 'credito_core' && props.row[props.row.origen][0].pivot.valor_tasa">
        {{ props.row[props.row.origen][0].pivot.valor_tasa }}%
      </span>
      <span v-else>N/A</span>
    </b-table-column>
    <b-table-column
      field="monto_base_calculo"
      label="Monto Base Calculo"
      v-slot="props"
      cell-class="text-right"
      header-class="text-right"
    >
      <span v-if="props.row.origen == 'credito_core'">
        {{ props.row[props.row.origen][0].pivot.valor_capital | currency | hp }}
      </span>
      <span v-else>N/A</span>
    </b-table-column>
    <template slot="empty">
      <div class="column flex justify-center">
        <empty-state
          type="svg"
          imagen="/images/movimientos.svg"
          titulo="No tienes movimientos financieros"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import { getLocalParam } from "@/vendors/helpers";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "OperacionesDiarias",
  props: {
    operaciones: Object
  },
  data() {
    return {
      perPage: getLocalParam("paginador") || 20,
      selected: null
    };
  },
  components: {
    EmptyState
  },
  watch: {
    selected(val) {
      this.$emit("selected", val);
    }
  },
  methods: {
    onPageChange(page) {
      this.$emit("page-change", page);
    },
    getTipo(tipo) {
      switch (tipo) {
        case "credito_core":
          return "credito";
        case "linea_credito":
          return "linea";
        case "pago_registro_diario":
          return "pago";
        case "cuenta_c_p":
          return "cuentaCP";
        default:
          return tipo;
      }
    }
  }
};
</script>
