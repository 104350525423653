<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Cambiar ejecutivo de la línea</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="mt-3">
            <ValidAutoComplete
                label="Ejecutivos"
                placeholder="Selecciona el nuevo ejecutivo..."
                rules="required"
                show="nombre"
                appendBody
                :custom="true"
                :data="ejecutivos"
                :expanded="true"
                @select="selectEjecutivo"
                @typing="buscarEjecutivo"
            />
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => cambiarEjecutivo(result))"
        >
          Cambiar
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidAutoComplete from '../form/ValidAutoComplete.vue';

import PersonasApi from "@/api/personas.api";
import { debounce } from "@/vendors/helpers";

export default {
    name: 'ModalCambiarEjecutivoLinea',
    components: {
        ValidAutoComplete,
        ValidationObserver,
    },
    data() {
    return {
      ejecutivos: [],
      ejecutivoId: null,
    };
  },
    props: {
        lineaId: Number,
    },
    methods: {
        async cambiarEjecutivo(result) {
            if (! result) return;
            
            await this.$store.dispatch("lineascredito/cambiarEjecutivoLinea", { 
                linea_id: this.lineaId, 
                ejecutivo_id: this.ejecutivoId 
            });

            this.$parent.close();
        },
        selectEjecutivo(ejecutivo) {
            this.ejecutivoId = ejecutivo.id
        },
        buscarEjecutivo: debounce(function(nombre) {
            if (!nombre.length) {
                return;
            }
            
            PersonasApi.buscarEjecutivo(nombre).then(res => this.ejecutivos = res?.data?.ejecutivos);
        }, 400),
    },
    created() {
        PersonasApi.buscarEjecutivo().then(res => this.ejecutivos = res?.data?.ejecutivos);
    }
}
</script>