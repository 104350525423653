<template>
  <div class="has-background-white-ter has-border-radius p-4 text-left">
    <p class="text-xl font-bold">Información de la línea</p>
    <div class="columns mt-2 is-multiline">
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Producto financiero</p>
        <p>
          {{
            getLinea.producto_financiero ? getLinea.producto_financiero.alias : "N/A"
          }}
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Ejecutivo de cuenta</p>
        <p>
          <preview v-if="getLinea.empleado" tipo="persona" :id="getLinea.empleado.id">
            {{ getLinea.empleado.nombre_completo.split("|")[0] }}
          </preview>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          {{ 
            getLinea.grupo_credito && getLinea.grupo_credito.nombre 
              ? "Grupo"
              : getLinea.pasiva ? "Fuente de fondeo" : "Acreditado" 
          }}
        </p>
        <p v-if="cliente">
          <preview tipo="persona" :id="cliente.id">
            {{ cliente.nombre }}
          </preview>
        </p>
        <p v-else-if="getLinea.grupo_credito && getLinea.grupo_credito.nombre">{{ getLinea.grupo_credito.nombre }}</p>
        <p v-else>N/A</p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Sucursal</p>
        <p>{{ getLinea.sucursal.nombre }}</p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Garantía</p>
        <p>{{ getLinea.tipos_garantia ? getLinea.tipos_garantia.nombre : "N/A" }}</p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Segmento de negocio</p>
        <p>
          {{ getLinea.segmento_negocio ? getLinea.segmento_negocio.nombre : "N/A" }}
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Revolvente</p>
        <p>
          {{ getLinea.revolvente ? "Sí" : "No" }}
        </p>
      </div>
    </div>
    <div class="rule-grey" />
    <div class="columns mt-2 is-multiline">
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Fecha de autorización</p>
        <p>
          <span v-if="getLinea.fecha_autorizacion">
            {{ getLinea.fecha_autorizacion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Fecha de caducidad</p>
        <p>
          <span v-if="getLinea.fechaCaducidad">
            {{ getLinea.fechaCaducidad | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          Fecha máxima para cambiar fuente de fondeo
        </p>
        <p>
          <span v-if="getLinea.fechaMaximaCambioFuenteFondeo">
            {{ getLinea.fechaMaximaCambioFuenteFondeo | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          Fecha máxima de contratación
        </p>
        <p>
          <span v-if="getLinea.fechaMaximaContratacion">
            {{ getLinea.fechaMaximaContratacion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>

      <div class="column is-4">
        <p class="has-text-grey-light font-light">
          Fecha máxima de disposición
        </p>
        <p>
          <span v-if="getLinea.fechaMaximaDisposicion">
            {{ getLinea.fechaMaximaDisposicion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
      <div class="column is-4">
        <p class="has-text-grey-light font-light">Fecha de recalificación</p>
        <p>
          <span v-if="getLinea.fecha_ultima_recalificacion">
            {{ getLinea.fecha_ultima_recalificacion | moment("DD-MM-YYYY") }}
          </span>
          <span v-else>N/A</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LineaInfoCard",
  props: {
    linea: Object
  },
  computed: {
    ...mapGetters("lineascredito", ["getLinea"]),
    cliente() {
      let relacion;
      if (this.getLinea.pasiva == 1 && this.getLinea.relaciones) {
        relacion = this.getLinea.relaciones.filter(
          x => x.rol.nombre == "Fuente de fondeo"
        );
      } else {
        relacion = this.getLinea.relaciones.filter(x => x.rol.nombre == "Cliente");
      }
      if (relacion.length > 0) {
        return {
          nombre: relacion[0].relacionado.nombre_completo.split("|")[0],
          id: relacion[0].relacionado.id
        };
      }
      return null;
    }
  }
};
</script>
