<template>
  <validation-observer
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-3xl">Agregar activo nuevo</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns">
            <div class="columns column is-7 is-multiline">
              <div class="column is-12">
                  <valid-input
                  label="Descripción"
                  rules="required"
                  type="textarea"
                  placeholder="Introduce una descripción del activo"
                  v-model="form.descripcion"
                  />
              </div>
              <div class="column is-12">
                  <valid-currency-input
                  expanded
                  rules="required|currency|notcero"
                  label="Valor"
                  placeholder="$0.00"
                  v-model="form.valor"
                  type="number"
                  />
              </div>
              <div class="column is-12">
                  <valid-select
                  expanded
                  rules="required"
                  label="Moneda"
                  placeholder="Selecciona uno"
                  v-model="form.moneda"
                  :data="getMonedas"
                  show="nombre"
                  realValue="id"
                  />
              </div>
              <div class="column is-12">
                  <valid-input
                  expanded
                  rules="required"
                  label="Identificador del bien"
                  placeholder="Introduzca un identificador"
                  v-model="form.identificador"
                  type="text"
                  />
              </div>
            </div>
            <div class="column is-5">
                <div
                  v-if="form.atributos.length > 0"
                  class="mt-2 has-background-arrendamiento-16 has-border-radius p-3"
                >
                  <p class="font-bold text-lg">Atributos</p>
                  <div v-for="atributo in form.atributos" :key="atributo.id">
                    <valid-input
                      rules="required"
                      :label="atributo.atributo.nombre"
                      v-if="atributo.atributo.tipo_dato_id == 1"
                      placeholder="Introduzca el número..."
                      v-model="atributo.value"
                      type="number"
                      :expanded="true"
                    />
                    <valid-input
                      rules="required"
                      :label="atributo.atributo.nombre"
                      v-if="atributo.atributo.tipo_dato_id == 3"
                      placeholder="Introduzca la información..."
                      v-model="atributo.value"
                      type="text"
                      :expanded="true"
                    />
                    <b-checkbox
                      class="has-text-grey-darker mt-2"
                      v-model="atributo.value"
                      v-if="atributo.atributo.tipo_dato_id == 6"
                      >{{ atributo.atributo.nombre }}</b-checkbox
                    >
                  </div>
                </div>
                <p class="font-bold text-lg mt-3">Archivos</p>
                <documentos
                    tipo="activo"
                    :documentos="form.files"
                    :outlined-btn="true"
                    :small="true"
                    @desligar="desligarDocumento"
                    @togglecf="toggleCapvitalFiles"
                />
            </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-arrendamiento"
          @click="validate().then(result => agregarActivo(result))"
          class="px-24"
        >
          Crear activo nuevo
        </b-button>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidInput from "@/components/form/ValidInput";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidSelect from "@/components/form/ValidSelect";
import Documentos from "@/components/generals/Documentos";

export default {
  name: "ModalAgregarActivo",
  components: {
    ValidationObserver,
    ValidInput,
    ValidCurrencyInput,
    ValidSelect,
    Documentos,
  },
  data() {
    return {
        form: {
            descripcion: null,
            tipo: 12,
            valor: null,
            moneda: null,
            identificador: null,
            atributos: [],
            files: [],
            arrendable: 1
        }
    };
  },
  props: {

  },
  watch: {
    getArchivosGarantia(files) {
        this.form.files = files;
    },
  },
  computed: {
    ...mapGetters("catalogos", ["getMonedas"]),
    ...mapGetters("lineascredito", ["getArchivosGarantia"]),

  },
  methods: {
    /*
      Se liga la garantía a la disposición
      @result (Boolean): Resultado de las validaciones por el validationObserver de los inputs
    */
    agregarActivo(result) {
      if (!result) {
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      form.files = this.form.files.map(file => file.id);
      let atributos = [];
      form.atributos.forEach((attr, index, array) => {
        if (attr.value == null) {
          array[index].value = 0;
        }
        atributos.push({
          id: array[index].atributo.id,
          valor: array[index].value
        });
      });
      form.atributos = atributos;
      form.linea_id = this.$route.params.idLinea;
      this.$store.dispatch("lineascredito/asignarGarantia", form).then(() => {
          this.form = {
            descripcion: null,
            tipo: null,
            valor: null,
            moneda: null,
            identificador: null,
            atributos: [],
            files: []
          };
          this.$parent.close();
      });
    },
    /*
      - @doc (Integer): Id del documento a desligar
    */
    desligarDocumento(doc) {
      this.form.files = this.form.files.filter(function(e) {
        return e.id !== doc;
      });
    },
    // Activa Capvital Files con la información necesaria del objeto a agregar documentos
    toggleCapvitalFiles() {
      this.$emit("toggle-cf", true);
    },
  },
  async created() {
    await this.$store.dispatch('catalogos/getMonedas');

    this.$store
      .dispatch("catalogos/getAtributosTipoGarantia", this.form.tipo)
      .then(data => {
        data.forEach(atributo => {
          this.form.atributos.push({
            atributo,
            value: null
          });
        });
      });
  }
};
</script>

<style>
@media (min-width: 1024px) {
  .modal.agregar-activo .modal-card {
      width: 980px;
  }
}
</style>