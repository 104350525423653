<template>
  <validation-observer
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-3xl">Agregar activo existente</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <valid-auto-complete
          rules="required"
          label="Buscar un activo para arrendar"
          placeholder="Buscar..."
          v-model="activoSearch"
          :data="activosExistentes"
          show="descripcion"
          class="text-left"
          @typing="buscarActivo"
          @select="setActivoExistente"
          :expanded="true"
        />
        <div class="h-32 mt-5">
            <div v-if="activoSelected">
                <p class="font-bold text-lg">Activo seleccionado</p>
                <garantia-card :garantia="activoSelected" :arrendamiento="true" :arrendando="true" />
            </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-arrendamiento"
          @click="validate().then(result => agregarActivo(result))"
          class="px-24"
        >
          Agregar activo
        </b-button>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import GarantiasApi from "@/api/garantias.api";
import { mapGetters } from "vuex";
import { debounce } from "@/vendors/helpers";
import { ValidationObserver } from "vee-validate";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import GarantiaCard from "@/components/cards/GarantiaCard";

export default {
  name: "ModalActivoExistente",
  components: {
    ValidationObserver,
    ValidAutoComplete,
    GarantiaCard
  },
  data() {
    return {
        activoSearch: "",
        activoSelected: null,
        activosExistentes: []
    };
  },
  props: {

  },
  watch: {
  },
  computed: {
    ...mapGetters("garantias", ["getGarantias"]),
  },
  methods: {
    buscarActivo: debounce(function(search) {
      if (!search.length) {
        return;
      }
      GarantiasApi.buscarActivoParaArrendamiento(search).then(res => {
        this.activosExistentes = res.data ? res.data.garantias : []
      });
    }, 400),
    setActivoExistente(activo) {
      this.activoSelected = activo;
    },
    async agregarActivo(res) {
      if (!res) return;
      
      let prelacion = 0;
      const garantia = await GarantiasApi.getGarantiaById(this.activoSelected.id);
      if (garantia.data && garantia.data.atributos.length > 0) {
        const prelacionGarania = garantia.data.atributos.find(atributo => atributo.nombre_atributo == 'Prelación');
        prelacion = prelacionGarania ? prelacionGarania.valor : 0;
      }

      const linea_id = this.$route.params.idLinea;
      const lineas_credito = {};
      lineas_credito[linea_id] = { prelacion };
      
      this.$store
        .dispatch("lineascredito/asignarGarantiaExistente", {
          garantia_id: this.activoSelected.id,
          lineas_credito
        })
        .then(() => this.$parent.close());
    }
  }
}
</script>