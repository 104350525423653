<template>
  <div
    class="card h-full has-background-white-ter has-border-radius p-4 text-left shadow-none overflow-hidden flex flex-col justify-center"
  >
    <p class="font-medium has-text-grey-darker sm:text-xl">
      <fa-icon icon="tasks" class="has-text-success" /> Checklist
    </p>
    <div class="text-4xl font-bold">
      <b-progress
        class="mt-8 mb-2"
        :type="type"
        :value="calcValue"
      ></b-progress>
      <p class="text-sm has-text-grey-light">
        {{ progreso }}/{{ total }} requisitos completados
      </p>
    </div>
    <b-button
      expanded
      class="mt-2"
      type="is-white"
      tag="router-link"
      :to="{ name: 'checklist_linea' }"
      >Ver Checklist</b-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ChecklistCard",
  props: {
    total: Number,
    progreso: Number,
    type: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("disposicion", ["getChangeStatus"]),
    nombre() {
      return this.status.nombre;
    },
    calcValue() {
      if (this.total == 0 || this.progreso == 0) {
        return 0;
      }
      return (this.progreso * 100) / this.total;
    }
  },
  methods: {}
};
</script>
