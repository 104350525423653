<template>
  <div class="text-left has-border-radius p-4 shadow-capvital border-b-2">
    <p class="text-xl font-bold">Actividad reciente</p>
    <div class="columns">
      <div
        class="column is-4"
        v-for="(actividad, key) in actividades"
        :key="key"
      >
        <div class="flex mt-4 items-center">
          <!-- {{ getInfo(key, actividad).fecha }} -->
          <actividad-icon-card
            :icon="getInfo(key, actividad).icon"
            :fecha="
              getInfo(key, actividad).fecha | moment('DD-MM-YYYY - h:mm A')
            "
            :titulo="getInfo(key, actividad).titulo"
            :contenido="getInfo(key, actividad).contenido"
            :bg-icon="getInfo(key, actividad).bgIcon"
            color-icon="has-text-white-ter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActividadIconCard from "@/components/cards/ActividadIconCard";
import { mapGetters } from "vuex";
import { setDocIcon, setLineaIcon } from "@/vendors/helpers";

export default {
  name: "LineaDetalleActividadReciente",
  components: {
    ActividadIconCard
  },
  data() {
    return {
      loading: true
    };
  },
  props: {
    tipo: String,
    id: Number
  },
  computed: {
    ...mapGetters("lineascredito", ["getActividad"]),
    actividades() {
      if (!this.getActividad) return [];
      for (let actividad in this.getActividad) {
        if (this.getActividad[actividad] == null)
          delete this.getActividad[actividad];
      }
      return this.getActividad;
    }
  },
  methods: {
    getInfo(key, data) {
      let requisito_data;
      switch (key) {
        case "documento":
          return {
            icon: setDocIcon(data.file.nombre).icon,
            fecha: data.updated_at,
            titulo: "Documento agregado",
            bgIcon: "has-background-primary",
            contenido: data.file.nombre
          };
        case "requisito_documento":
          return {
            icon: setDocIcon(data.files[0].nombre).icon,
            fecha: data.updated_at,
            titulo: "Documento a requisito",
            bgIcon: "has-background-primary",
            contenido: data.requisito.nombre
          };
        case "disposicion":
          return {
            icon: "wallet",
            fecha: data.updated_at,
            titulo: "Disposición agregada",
            bgIcon: data.creditos_core.pasivo
              ? "has-background-pasivo"
              : "has-background-primary",
            contenido: `#${
              data.creditos_core.creditos_autorizados_id
                ? data.creditos_core.creditos_autorizados_id
                : data.creditos_core_id
            } - $${Intl.NumberFormat().format(
              data.creditos_core.capital_otorgado
            )} `
          };
        case "requisito":
          requisito_data = data.status
            ? this.getRequisitoIcon(data.status.nombre)
            : { icon: "tasks", bgIcon: "has-background-primary" };
          return {
            icon: requisito_data.icon,
            fecha: data.updated_at,
            titulo: `Requisito ${
              data.status ? data.status.nombre : "Agregado"
            }`,
            bgIcon: requisito_data.bgIcon,
            contenido: data.requisito.nombre
          };
        case "garantia":
          return {
            icon: "landmark",
            fecha: data.updated_at,
            titulo: "Garantía agregada",
            bgIcon: "has-background-primary",
            contenido: `#${data.garantia.id}`
          };
        case "contrato":
          return {
            icon: "file-contract",
            fecha: data.updated_at,
            titulo: "Contrato modificado",
            bgIcon: "has-background-grey-darker",
            contenido: data.numero
          };
        case "requisito_comentario":
          return {
            icon: "comment",
            fecha: data.updated_at,
            titulo: "Comentario a requisito",
            bgIcon: "has-background-grey-darker",
            contenido: data.requisito.nombre
          };
        case "status_linea":
          return {
            icon: setLineaIcon(data.status_linea.nombre).icon,
            fecha: data.updated_at,
            titulo: "Cambio de estatus",
            bgIcon: "has-background-grey-darker",
            contenido: data.status_linea.nombre
          };
        case "cuenta_cp":
          return {
            icon: "money-check-alt",
            fecha: data.updated_at,
            titulo: `Creación de cuenta ${
              data.cuenta_c_p.cobro ? "por cobrar" : "por pagar"
            } `,
            bgIcon: "has-background-grey-darker",
            contenido: `#${data.cuentas_cobrar_pagar_id}`
          };
      }
    },
    getRequisitoIcon(nombre) {
      switch (nombre) {
        case "Aceptado":
          return {
            icon: "check",
            bgIcon: "has-background-success"
          };
        case "Rechazado":
          return {
            icon: "times",
            bgIcon: "has-background-danger"
          };
        case "En revisión":
          return {
            icon: "info",
            bgIcon: "has-background-info"
          };
        case "Reportado a organo supervisor":
          return {
            icon: "check",
            bgIcon: "has-background-success"
          };
        case "Modificado":
          return {
            icon: "exclamation",
            bgIcon: "has-background-warning"
          };
      }
    }
  },
  mounted() {
    switch (this.tipo) {
      case "linea":
        this.$store
          .dispatch("lineascredito/getActividadReciente", this.id)
          .then(() => {
            this.loading = false;
          });
        break;
    }
  }
};
</script>
