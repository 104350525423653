<template>
  <div class="flex items-center w-full">
    <div
      class="w-16 min-w-16 h-16 has-border-radius flex items-center justify-center"
      :class="bgIcon"
    >
      <b-icon :icon="icon" size="is-large" :class="colorIcon" />
    </div>
    <div class="ml-4 calcw w-3/4">
      <p class="text-xs has-text-grey-light">{{ fecha }}</p>
      <p class="text-xl font-medium">{{ titulo }}</p>
      <p class="text-sm truncate">{{ contenido }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActividadIconCard",
  props: {
    icon: String,
    fecha: String,
    titulo: String,
    contenido: String,
    bgIcon: {
      type: String,
      default: "has-background-grey-darker"
    },
    colorIcon: {
      type: String,
      default: "has-text-white-ter"
    }
  }
};
</script>

<style scoped>
.calcw {
  width: calc(100% - 4rem);
}
</style>
