<template>
  <div class="columns">
    <div class="column is-4 text-left" v-if="getContrato">
      <p class="text-6xl font-bold">
        <fa-icon icon="file-contract" class="has-text-info" />
      </p>
      <p class="text-2xl font-bold leading-tight">
        Contrato
        <span class="text-lg leading-tight">{{ getContrato.numero }}</span>
      </p>
      <p class="capitalize font-light">
        {{ getContrato.descripcion }}
      </p>
      <div class="mt-4">
        <p class="font-light has-text-grey-light">Fecha de firma</p>
        <p>{{ fechaFirma }}</p>
        <p class="font-light has-text-grey-light mt-3">
          Vencimiento de contrato
        </p>
        <p>{{ vencimientoContrato }}</p>
        <p class="font-light has-text-grey-light mt-3">Capital</p>
        <p>{{ getContrato.capital | currency }}</p>
      </div>
      <div
        class="mt-2"
        v-for="(ciclo, index) in getContrato.ciclos"
        :key="ciclo.id"
      >
        <div class="has-background-white-ter has-border-radius p-2">
          <p class="text-xs has-text-grey-light">Ciclo {{ index + 1 }}</p>
          <p>
            Del {{ ciclo.fecha_inicial | moment("DD-MM-YYYY") }} al
            {{ ciclo.fecha_final | moment("DD-MM-YYYY") }}
          </p>
        </div>
      </div>
    </div>
    <div class="column hidden md:block" v-if="getContrato">
      <b-image src="/images/contract_image.svg" alt class="w-full" />
    </div>
    <div class="column is-12 text-center" v-else>
      <empty-state
        type="svg"
        vertical
        imagen="/images/contrato.svg"
        titulo="No Hay contrato"
        subtitulo="Puedes agregar un contrato a esta línea en el botón de abajo."
      />
      <b-button
        :type="linea.pasiva ? 'is-pasivo' : 'is-primary'"
        icon-left="plus"
        tag="router-link"
        :to="{ name: 'contrato' }"
        >Asignar contrato</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "LineaDetalleContrato",
  components: {
    EmptyState
  },
  data() {
    return {
      activePersona: null
    };
  },
  props: {
    linea: Object
  },
  computed: {
    ...mapGetters("lineascredito", ["getContrato"]),
    fechaFirma() {
      return this.getContrato.fecha_firma == "0000-00-00"
        ? "N/A"
        : this.$moment(this.getContrato.fecha_firma).format("DD-MM-YYYY");
    },
    vencimientoContrato() {
      return this.getContrato.vencimiento_contrato == "0000-00-00"
        ? "N/A"
        : this.$moment(this.getContrato.vencimiento_contrato).format(
            "DD-MM-YYYY"
          );
    }
  },
  async mounted() {
    await this.$store.dispatch(
      "lineascredito/getContrato",
      this.$route.params.idLinea
    );
  }
};
</script>
